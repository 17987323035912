import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Text from "@ui/Text/Text"
import CenterLayout from "@components/centerlayout"

import { DividerIllustration } from "../components/DividerIllustration"
import { rem } from "polished"
import Button from "@ui/Button/Button"
import { Link as GatsbyLink } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <DividerIllustration loading="eager" />
    <CenterLayout my="5xl" maxWidth={rem("640px")} textAlign="center">
      <Text size="5xl" as="h1" fontWeight="bold">
        Page not found
      </Text>
      <Text>
        The page you were looking for could not be found. <br /> It might have
        been removed, renamed or did not exist in the first place.
      </Text>
      <Button variant="primary" mt="2xl" as={GatsbyLink} to="/">
        Back to home
      </Button>
    </CenterLayout>
  </Layout>
)

export default NotFoundPage
